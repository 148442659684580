@import "../../../scss/repeat.scss";
@import "../../../scss/colors.scss";

.footer {
  @extend %container;

  padding: 52px 52px 40px;

  background-color: $neutrals_N0;

  border-radius: 16px 16px 0 0;

  &__container {
    @extend %alignedRow;

    &:first-child {
      padding-bottom: 40px;
      margin-bottom: 40px;

      border-bottom: 1px solid $neutrals_N100;
    }

    &:last-child {
      align-items: flex-end;
    }
  }

  &__info {
    flex: 1;

    h3,
    span,
    & + span {
      @extend %subheading_H5_Desktop;

      color: $neutrals_N1000;
    }

    & + span,
    span:last-child {
      color: $neutrals_N500;
    }
  }

  &__container:last-child > &__info {
    display: none;
  }

  a {
    color: $neutrals_N1000;

    padding: 16px 20px;

    #line {
      bottom: 10px;
      left: 20px;

      background-color: $neutrals_N1000;
    }

    svg path {
      fill: $neutrals_N1000;
    }
  }

  @media (max-width: $largeTablet) {
    width: 100%;
    padding: 20px 24px;

    &__container:first-child {
      flex-wrap: wrap;

      padding-bottom: 8px;
      margin-bottom: 8px;
    }

    &__container:first-child > &__info {
      display: none;
    }

    &__container:last-child > &__info {
      display: flex;
    }

    a {
      padding: 8px;

      #line {
        left: 8px;
        bottom: 2px;
      }
    }
  }

  @media (max-width: $largeMobile) {
    &__info {
      h3,
      span,
      & + span {
        @include subheading_H2_Mobile();
      }
    }
  }
}
