/* ---------------------------------- Other --------------------------------- */

//!Some of them are deprecated.Need to be further refactored
$other_V500: #714fc3;
$other_Yell500: #ffd500;
$other_Bl500: #005bbb;
$wildMindsBg: #c3bf3d;
$demonstrationBg: #10151a;
/* -------------------------------- Neutrals -------------------------------- */
//!Some of them are deprecated.Need to be further refactored
$neutrals_N0: #ffffff;
$neutrals_N100: #f6f6f6;
$neutrals_N200: #eae9e9;
$neutrals_N300: #e9deff;
$neutrals_N500: #888888;
$neutrals_N700: #05151e;
$neutrals_N800: #292d38;
$neutrals_N900: #191c23;
$neutrals_N1000: #070707;

/* --------------------------------- Linear --------------------------------- */
$linearPurple: linear-gradient(
  180deg,
  rgba(7, 7, 7, 0.81) -2.83%,
  rgba(101, 95, 212, 0.81) 29.84%,
  rgba(56, 53, 115, 0.81) 72.69%,
  rgba(7, 7, 7, 0.81) 100%
);
$linearCard: linear-gradient(
  243deg,
  #070707 -0.89%,
  rgba(41, 45, 44, 0) 101.07%
);
$linearCardStroke: linear-gradient(
  244deg,
  #080808 0%,
  rgba(251, 255, 250, 0) 100%
);
$linearGreen: linear-gradient(
  243deg,
  rgba(6, 166, 142, 0.28) -0.89%,
  rgba(1, 34, 29, 0.8) 101.07%
);

/* --------------------------------- Accent --------------------------------- */
//!Some of them are deprecated.Need to be further refactored
$accent_A200: rgba(28, 0, 94, 0.35);
$accent_A500: linear-gradient(180deg, #7650f5 0%, #301881 100%);
$accent_A600: linear-gradient(180deg, #361c8a 0%, #230d6f 100%);
