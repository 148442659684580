/* ------------------------------- Font names ------------------------------- */

//!!Deprecated:
$kyivTypeTitling: "Kyiv Type Titling";
$kyivTypeTitling2: "Kyiv Type Titling2";
$kyivTypeSans: "Kyiv Type Sans";

//??New:
$jura: "Jura";

/* ----------------------------- Container size ----------------------------- */

$containerDesktopMaxWidth: 1176px;
$designDesktopLayoutWidth: 1440px;

$containerMobileMaxWidth: 342px;
$designMobileLayoutWidth: 390px;

/* ------------------------------- Break points ------------------------------ */
$largeDesktop: 1920px;
$desktop: 1536px;
$smallDesktop: 1280px;

$largeTablet: 1024px;
$tablet: 820px;
$smallTablet: 601px;

$largeMobile: 414px;
$mobile: 375px;
$smallMobile: 320px;
