@import "./constants";
@import "./colors";
@import "./utils.scss";
@import "./typography";

%button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: none;
  outline: none;
  border: none;

  cursor: pointer;

  position: relative;

  height: 68px;

  border-radius: 12px;

  &::after {
    transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
    content: "";

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    border-radius: inherit;
    border: 2px solid transparent;

    width: inherit;
    height: inherit;

    background: $accent_A500 border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  &:active::after {
    background-image: $accent_A600;
  }
}

%smallButton {
  @extend %button;

  width: 48px;
  height: 48px;

  border-radius: 50%;

  &:hover::after {
    border-width: calc(48px / 2);
  }
}

%center {
  display: flex;
  align-items: center;
  justify-content: center;
}

%absoluteCenter {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

%alignedRow {
  display: flex;
  align-items: center;
  flex-direction: row;
}

%scrollList {
  overflow-y: overlay;
  &::-webkit-scrollbar {
    width: 10px;
    position: relative;
    z-index: 100;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    transition: background-color 4s linear;
    border-radius: 10px;
    background-color: #b9b9b9f8;
    &:active {
      background-color: #000;
    }
  }
}

%container {
  //fullScreen width in design:1440 px; horizontal padding=132px;1440-132*2=1176 px;

  max-width: $containerDesktopMaxWidth;
  width: calculatePercentage(
    $containerDesktopMaxWidth,
    $designDesktopLayoutWidth
  );

  align-self: center;

  @media (max-width: $largeTablet) {
    width: calc(100% - (24px * 2));
  }
}

%loopSection {
  background-color: $neutrals_N0;
}
