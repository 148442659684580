html {
  box-sizing: border-box;
  height: fit-content;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
p {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
}
a:focus {
  outline: none;
}

body {
  font-family: $jura;

  color: $neutrals_N0;

  background: $neutrals_N1000;

  overflow-y: overlay !important;
  overscroll-behavior-y: none;
  overflow-x: hidden !important;
}

section {
  overflow: hidden;
}
div,
ul,
ol,
section,
header,
footer {
  display: flex;
  flex-direction: column;
}
